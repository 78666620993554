import { Container } from "./styles";

import { useState } from "react";

import { BtnSecondary } from "../Button/Btn";
import { Link, useLocation, useNavigate } from "react-router-dom";
type Headerprops = {
  openModal: () => void;
  className?: string;
};

export function Header({ openModal, className }: Headerprops) {
  const [isActive, setActive] = useState(false);
  const naviate = useNavigate();
  const { pathname } = useLocation();

  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  const navigator = (path: string) => {
    setActive(false);
    naviate(path);
    // console.log("click", path);
  };

  return (
    <Container className={` ${className} header-fixed  !bg-inherit`}>
      <div className="logo pt-[32px]">
        <Link to={"/"} title="logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <g clip-path="url(#clip0_87_161)">
              <path
                d="M51.2597 0H12.7403C5.70401 0 0 5.70401 0 12.7403V51.2597C0 58.296 5.70401 64 12.7403 64H51.2597C58.296 64 64 58.296 64 51.2597V12.7403C64 5.70401 58.296 0 51.2597 0Z"
                fill="white"
              />
              <path
                d="M27.4688 49.8346V24.3541H16.9728V14.1653H35.8571V49.8346H27.4688Z"
                fill="url(#paint0_linear_87_161)"
              />
              <path
                d="M47.0272 24.5247H37.9563V22.4255H44.928V20.4031H37.9563V14.1567H47.0272V16.2559H40.0555V18.3039H47.0272V24.5247Z"
                fill="url(#paint1_linear_87_161)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_87_161"
                x1="26.4149"
                y1="14.1653"
                x2="26.4149"
                y2="49.8346"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9413" />
                <stop offset="1" stop-color="#FF6B0B" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_87_161"
                x1="42.4917"
                y1="14.1567"
                x2="42.4917"
                y2="24.5247"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF9413" />
                <stop offset="1" stop-color="#FF6B0B" />
              </linearGradient>
              <clipPath id="clip0_87_161">
                <rect width="64" height="64" rx="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>

      <input
        onChange={toggleTheme}
        className="container_toggle"
        type="checkbox"
        id="switch"
        name="mode"
      />

      <nav className={isActive ? "active" : ""}>
        <div className="md:space-x-12 md:space-y-0 flex flex-col md:flex-row text-center justify-center items-center space-y-5">
          {/* <button
            onClick={() => navigator("/")}
            className="text-[#242527] text-[24px] Poppins-font "
          >
            Blogs
          </button>

          <button
            onClick={() => navigator("/support")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            Help & Support
          </button> */}
        </div>
        <div className=" flex flex-col md:hidden !text-center justify-center !items-center space-y-5 ">
          <button
            onClick={() => navigator("/privacy")}
            className="text-[#242527] text-[24px] Poppins-font "
          >
            Privacy
          </button>

          <button
            onClick={() => navigator("/terms")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            Terms
          </button>

          {/* <button
            onClick={() => navigator("/faqs")}
            className="text-[#242527] text-[24px] Poppins-font"
          >
            FAQs
          </button> */}
        </div>

        <BtnSecondary
          onClick={() => {
            openModal();
          }}
          className=" w-[236px] text-[24px] h-[64px] ml-8 hidden md:flex text-center justify-center text-black salsa-regular "
        >
          {" "}
          {pathname === "/" || pathname === "/home"
            ? "Download now"
            : "Get The Tej"}
        </BtnSecondary>
      </nav>

      <div
        aria-expanded={isActive ? "true" : "false"}
        aria-haspopup="true"
        aria-label={isActive ? "Fechar menu" : "Abrir menu"}
        className={isActive ? "menu active" : "menu"}
        onClick={() => {
          setActive(!isActive);
        }}
      ></div>
    </Container>
  );
}
