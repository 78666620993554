import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SecHeader = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState("");

  //   console.log(pathname);
  useEffect(() => {
    if (pathname == "/support") {
      setPage("Contact Us");
    } else if (pathname == "/terms") {
      setPage("Terms & Conditions");
    } else if (pathname == "/privacy") {
      setPage("Privacy Policy");
    } else {
      setPage("404 - Not Found");
    }
  }, [pathname]);

  return (
    <>
      <div className="flex gap-4 items-center text-[#242527] text-[18px] py-3">
        <Link to={"/"} className="cursor-pointer">
          <h2 title="Home">Home</h2>
        </Link>
        <div className="h-8 border border-b-2 border-black"></div>
        <h2>{page}</h2>
      </div>
    </>
  );
};

export default SecHeader;
