import { Route, Routes } from "react-router-dom";
import { Main } from "./components/Main/Main";
import Terms from "./pages/Terms/Terms";
import FAQs from "./pages/FAQs/FAQs";
import Privacy from "./pages/Privacy/Privacy";
import Support from "./pages/Supports/Support";
import Layout from "./components/Layout";
import ErrorPage from "./pages/Error/ErrorPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}></Route>

        <Route element={<Layout />}>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          {/* <Route path="/faqs" element={<FAQs />}></Route> */}
          <Route path="/support" element={<Support />}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
