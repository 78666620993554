import { FC, ReactNode, ButtonHTMLAttributes } from "react";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  children: ReactNode;
};

export const BtnPrimary: FC<ButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div>
      <button
        className={
          " items-center border-2 border-solid border-white rounded-[130px] text-[20px] md:text-[24px] px-[20px] md:px-[30px]  py-[10px] hover:scale-105 hover:duration-500 text-nowrap " +
          className
        }
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export const BtnSecondary: FC<ButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div>
      <button
        className={
          " items-center border-2 border-solid border-black hover:border-white rounded-[130px]  text-black hover:text-white hover:duration-500 hover:ease-in-out  " +
          className
        }
        {...rest}
      >
        {children}
      </button>
      {/* </button> */}
    </div>
  );
};
