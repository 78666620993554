import { Outlet } from "react-router-dom";
import { Header } from "./Header/Header";
import { useState } from "react";
import SecHeader from "./Header/SecHeader";
import { PopUpLayout } from "./PopUp/PopUpLayout";

const Layout = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div className=" Poppins-font ">
      <Header
        openModal={openModal}
        className=" bg-gradient-to-b from-[#FF9413] to-[#FF6B0B] !z-10"
      />
      <div className=" mt-[108px] md:mt-[132px] bg-[#FAFAFA] pl-[20px] md:pl-[120px] sticky top-[108px] md:top-[132px]  ">
        <SecHeader />
      </div>

      <Outlet />
      <PopUpLayout
        closeModal={closeModal}
        openModal={openModal}
        isOpen={isOpen}
      ></PopUpLayout>
    </div>
  );
};

export default Layout;
