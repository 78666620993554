import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import { NavHashLink, HashLink } from "react-router-hash-link";
import { useState } from "react";

import CV from "../../assets/CV_Sagar_K.pdf";
import { BtnSecondary } from "../Button/Btn";
import { Link } from "react-router-dom";
export function Footer() {
  const [isActive, setActive] = useState(false);

  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  function closeMenu() {
    setActive(false);
  }

  function getCurrentYear(): number {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  const year = getCurrentYear();

  return (
    <Container className="header-fixed">
      <nav className={isActive ? "active" : ""}>
        <div className="space-x-12 Poppins-font pb-[32px]">
          <Link to={"/privacy"}>
            <button
              className="text-[#242527] text-[8px] md:text-[14px] salsa-regular hover:underline font-semibold hover:font-bold hover:text-white"
              title="Privacy"
            >
              Privacy
            </button>
          </Link>
          <Link to={"/terms"}>
            <button
              className="text-[#242527] text-[8px] md:text-[14px] salsa-regular hover:underline font-semibold hover:font-bold hover:text-[#ffffff]"
              title="Terms"
            >
              Terms
            </button>
          </Link>
          {/* <Link to={"/faqs"}>
            <button className="text-[#242527] text-[24px] font-['Poppins']">
              FAQs
            </button>
          </Link> */}
        </div>
      </nav>
      <button
        className="text-[#242527] text-[8px] md:text-[14px] salsa-regular  md:text-end "
        title="@2024 Tej Social"
      >
        @{year} Tej Social
      </button>
    </Container>
  );
}
