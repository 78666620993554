import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center justify-center h-[calc(100vh-180px)] ">
        <div className="text-center  md:text-[61px] text-[32px] tracking-widest md:tracking-normal ">
          <h1>
            {" "}
            <span className="text-[#FF9914]">We are working on it</span>
          </h1>
          <button
            className="text-[22px]"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
